<template lang="pug">
// 匯出CSV
v-card
  v-card-title.d-flex.justify-center {{$t('IMPORT_EXPORT.TITLE_2')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-select.no-border(
        :label=`$t('FLEXFIELD.SELECT_TABLE')`,
        v-model="currnetTableId",
        prepend-icon="fa-chart-pie",
        :items="customerAttributeList",
        item-text="name",
        item-value="id",
        flat,
        :rules='formRule.fieldRules',
      )
      v-text-field.no-border(
        :label=`$t('IMPORT_EXPORT.SEARCH')`,
        v-model="search_str",
        prepend-icon="icon-search",
        flat,
      )
    //- 
    v-row.mt-2
      v-col.pr-2(cols="6")
        v-btn(
          v-hide-element='false'
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          v-hide-element='false'
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          :disabled='exporting || !fisrt_loading',
        ) {{exporting ? $t('IMPORT_EXPORT.ING') : $t('IMPORT_EXPORT.DOWNLOAD')}}
          v-icon(size='16' color='black' :disabled='true' v-if='exporting') fas fa-circle-notch fa-spin
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import ResourceDataService from "@/services/ResourceDataService";
import messageDialog from '@/components/Dialog/redirectDialog';
import i18n from '/common/plugins/vue-i18n.js' 
import errorDialog from "@/components/Dialog/errorDialog";

export default Vue.extend({
      props: {    
        page_name: {
            type: String,
            required: true,
        },
        search: {
            type: String,
            required: false,
        },
        filter_id: {
            required: false,
        },
        owner: {
            required: false,
        },
        customerAttributeList:{
            type:Array,
            require:true
        },
        currentAttributeId:{
            type:Number,
            require:true
        },
    },
    components: {
        messageDialog,
        errorDialog,
    },
    data() {
        return {
            errorDialog:false,
            errorMessage:'',
            dataType:'db',
            page:null,
            stage:'',
            stageCaseList:[],
            stageOpptyList:[],
            stageTypeList:[],
            filter_list : [],
            all_filter_list : [{"id":0,"name":i18n.t('FILTERSETTING.NO_FILTER')}],
            filter:0,
            filter_data:null,
            typeList:[
                { key:'temp', name: i18n.t('IMPORT_EXPORT.TEMP')},
                { key:'db', name: i18n.t('IMPORT_EXPORT.DB_DATA')}
            ],
            messageDialog:false,
            message:"",
            valid: true,
            isLoading: false,
            formRule: {
                fieldRules: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
            },
            user: JSON.parse(window.localStorage.getItem('user')),
            allCustomField: [],
            CFList: null,
            search_str:'',
            fisrt_loading:false,
            exporting:false,
            currnetTableId:null,
        };
    },
    async created(){
        this.currnetTableId = this.currentAttributeId;
        this.page = this.page_name;
        if(this.search)
        {
            this.search_str = this.search;
        }
        this.fisrt_loading = true;
    },
    methods: {
        validateForm() {
            if (this.$refs.form.validate()) 
                return true;
            else 
                return false;
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        async onConfirm() {
        if (this.validateForm()) {
            this.exporting = true
            await ResourceDataService.export_flexfield(this.currnetTableId,this.search_str,this.dataType)
            .then(response => {
                if ('download' in document.createElement('a')) {
                let link = document.createElement('a')
                let now = new Date()
                let yyyy = now.getFullYear()
                let MM = (now.getMonth()+1).toString().padStart(2,'0')
                let dd = now.getDate().toString().padStart(2,'0')
                let hh = now.getHours().toString().padStart(2,'0')
                let mm = now.getMinutes().toString().padStart(2,'0')
                let ss = now.getSeconds().toString().padStart(2,'0')
                let currentTime = `${yyyy}-${MM}-${dd} ${hh}${mm}${ss}`
                let export_type = this.dataType=='temp'? '[Template]':''
                let table_name = this.customerAttributeList.find(el => el.id == this.currnetTableId).name;
                link.style.display = 'none'
                link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(response.data)
                link.download = `${this.page} ${table_name} ${export_type} ${currentTime}.csv`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                this.errorMessage = i18n.t('IMPORT_EXPORT.ERROR')
                this.errorDialog = true;
            }
                this.$emit("emitExport");
                this.exporting = false;
            });
        }
        },
        onCancel() {
            this.$emit("emitExport");
        },
        showMessageDialog() {
        },
      },
      watch:{
        "page":function () {
        },
      }
});
</script>